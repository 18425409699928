import "./scss/main.scss";
import "bootstrap";
const menuBtn = document.querySelector(".menu-btn");
const hamburger = document.querySelector(".menu-btn__burger");
const nav = document.querySelector(".nav");
const menuNav = document.querySelector(".menu-nav");
const navItems = document.querySelectorAll(".menu-nav__item");
let showMenu = !1;
menuBtn.addEventListener("click", toggleMenu);
function toggleMenu() {
  if (!showMenu) {
    hamburger.classList.add("open");
    nav.classList.add("open");
    menuNav.classList.add("open");
    navItems.forEach((item) => item.classList.add("open"));
    menuBtn.setAttribute("aria-expanded", "true");
    showMenu = !0;
  } else {
    hamburger.classList.remove("open");
    nav.classList.remove("open");
    menuNav.classList.remove("open");
    navItems.forEach((item) => item.classList.remove("open"));
    menuBtn.setAttribute("aria-expanded", "false");
    showMenu = !1;
  }
}
document.addEventListener("keydown", function (event) {
  if (event.key === "Escape" || event.key === 27) {
    if (showMenu) {
      toggleMenu();
    }
  }
});
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("show");
    } else {
      entry.target.classList.remove("show");
    }
  });
});
const hiddenElements = document.querySelectorAll(".hidden");
hiddenElements.forEach((el) => observer.observe(el));
let mybutton = document.getElementById("toTopBtn");
const PixelFromTop = 100;
const styleDisplayBlock = "block";
const styleDisplayNone = "none";
window.onscroll = function scrollFunction() {
  if (mybutton === null) return;
  if (
    document.body.scrollTop > PixelFromTop ||
    document.documentElement.scrollTop > PixelFromTop
  ) {
    mybutton.style.display = styleDisplayBlock;
  } else {
    mybutton.style.display = styleDisplayNone;
  }
};
